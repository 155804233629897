<template>
  <div class="el-main-report">
    <div  style="display:flex;justify-content: center;" >
    <div style="width: 375px">
      <pdf
          v-for="i in numPages"
          :key="i"
          :src="src"
          :page="i"
          style="display: inline-block; width: 96%;margin-left: 2%"
      ></pdf>
    </div>
    </div>
  </div>
</template>

<script>
  import { getReportDetailApi} from "@/api/weeklyApi";
  import pdf from 'vue-pdf';

  export default {
    components: {
      pdf
    },
    data() {
      return {
        dialogImageUrl: "",
        pageTotalNum: 1, // 总页数
        pageNum: 1, // 当前页数
        src: '',
        numPages: undefined,
      };
    },
    mounted() {
      this.getDetail()
    },
    methods: {
      getDetail(){
        getReportDetailApi({
          params: {
            key: this.$route.query.key,
            date: this.$route.query.date,
          },
        }).then((ret) => {
          console.info(ret.data.data)
          this.dialogImageUrl = ret.data.data.fileUrl;
          //window.location.href = ret.data.data.fileUrl;
          var loadingTask = pdf.createLoadingTask(ret.data.data.fileUrl);
          this.src = ret.data.data.fileUrl
          loadingTask.promise.then(pdf => {
            this.numPages = pdf.numPages;
          });
        });
      },
      // 上一页
      prePage() {
        let page = this.pageNum;
        if (page > 1){
          page -= 1;
          this.pageNum = page;
        }else {
          this.$message.warning("已经是第一页了")
        }
      },
      // 下一页
      nextPage() {
        let page = this.pageNum;
        if (page < this.pageTotalNum){
          page+=1;
          this.pageNum = page;
        }else {
          this.$message.warning("已经是最后一页了")
        }
      },
    },
  };
</script>

<style lang="scss" >
.el-main-report {
  width: 100%!important;
  height: 100%!important;
  background:  #FFFFFF 100% !important;
  overflow-x:hidden;
}
</style>