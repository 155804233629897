<template>
  <div class="el-main-weekly" >
    <div  style="display:flex;justify-content: center;" >
      <div  class="weekly-background">
        <img v-if="weeklyInfo.tenantId == 11" style="position: absolute;left: 12px;top: 14.5px;width: 143px;height: 26px" src="@/assets/image/weekly/logo.png" alt=""/>
        <img v-if="weeklyInfo.tenantId != 11 && weeklyInfo.tenantId != 2" style="position: absolute;left: 12px;top: 14.5px;width: 103px;height: 24px" src="@/assets/image/weekly/logo-q.png" alt=""/>

        <img  style="margin-left: 293px;width: 70px;height: 58.5px" src="@/assets/image/weekly/date.png" alt=""/>
        <img  style="position: absolute;left: 12.5px;top: 88.5px;width: 193px;height: 118.5px" src="@/assets/image/weekly/wenan.png" alt=""/>
        <img  style="position: absolute;left: 154px;top: 63.5px;width: 221px;height: 213.5px" src="@/assets/image/weekly/ch.png" alt=""/>
        <div style="position: absolute;left: 300px;top: 8.5px;display: flex;flex-direction: column;height: 100vh;text-align: center;" >
          <div>
            <span  class="weekly-punish-date">{{year}}年</span>
          </div>
          <div>
            <span  class="weekly-punish-date">{{weeklyInfo.beginDate}}-{{weeklyInfo.endDate}}</span>
          </div>
        </div>

        <div v-if="clickFlag">
          <img style="position: absolute;z-index: 999;left: 339.5px;top: 285.5px;width: 28.5px;height: 28.5px" src="@/assets/image/weekly/shouzhi.png" alt=""/>
          <span class="weekly-span-style1">本周处罚详情
          </span>
          <span @click="filtrate(false)"  class="weekly-span-style2"> {{year}}年处罚分析</span>
          <div  class="weekly-tags-left"></div>
          <img  style="position: absolute;z-index:-999;left: 7px;top: 323px;width: 361px;height: 541.5px" src="@/assets/image/weekly/di.png" alt=""/>
          <div style="margin-top: 290px;"></div>
          <div v-if="weeklyInfo.config.bzfdsFlag">
            <div style="position: absolute;z-index:-998;left: 26px;top: 347px;width: 325px;height: 300px;background: #FFFFFF;opacity: 0.5;border-radius: 10px;"></div>
            <div class="weekly-table-bankgroud">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/biaoti.png" alt=""/>
                <span class="weekly-font-title"> 本周罚单数 </span>
                <img src="@/assets/image/weekly/biaoti.png" alt=""/>
              </div>

              <div style="width: 310px;margin-left: 23px;margin-top: -10px;margin-bottom: 11px">
                <span class="weekly-font-table">本周（{{weeklyInfo.tableDetailRespVO.beginDate}}-{{weeklyInfo.tableDetailRespVO.endDate}}），重点关注地区{{weeklyInfo.tableDetailRespVO.subNameStr}}收到的罚单数如下：</span>
              </div>


              <div class="regional-table">
                <div class="regional-table-bankground">
                  <el-table
                          :data="weeklyInfo.tableList"
                          border
                          :row-style="{height: 30+'px',background: 'transparent !important'}"
                          :cell-style="{padding:0+'px','text-align':'center',background: 'transparent !important','font-size': '14px','font-family': 'Source Han Sans CN','font-weight': 'bold',color: '#15233B 100%','border-color':'#008AFF'}"
                          :header-cell-style="{height: 30+'px',padding:0+'px',background:'#008AFF','text-align':'center','font-size': '14px','font-family': 'Source Han Sans CN','font-weight': 'bold',color: '#FFFFFF','border-color':'#008AFF'}"
                          class="weekly-el-table-st"
                  >
                    <el-table-column prop="subName" label="机构" width="100">
                        <template slot-scope="scope">
                            <div @click="goAnchor('#' + scope.row.subName,scope.row.number)" :style="scope.row.number == 0 ? {}:{cursor: 'pointer'}">{{ scope.row.subName }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="number" label="数量" width="60">
                      <template slot-scope="scope">
                        <div @click="goAnchor('#' + scope.row.subName,scope.row.number)" :style="scope.row.number == 0 ? {color: '#15233B 100%'}:{ 'text-decoration':'underline',color: '#008AFF',cursor: 'pointer'}">{{ scope.row.number }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="subName1"  label="机构" width="100">
                        <template slot-scope="scope">
                            <div @click="goAnchor('#' + scope.row.subName1,scope.row.number1)" :style="scope.row.number1 == 0 ? {}:{cursor: 'pointer'}">{{ scope.row.subName1 }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="number1"  label="数量" width="59">
                      <template slot-scope="scope">
                        <div @click="goAnchor('#' + scope.row.subName1,scope.row.number1)" :style="scope.row.number1 == 0 ? {color: '#15233B 100%'}:{'text-decoration':'underline',color: '#008AFF',cursor: 'pointer'}">{{ scope.row.number1 }}</div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>

              <div style="width: 320px;background: rgba(0,138,255,0.05);border: 1px solid #008AFF;border-radius: 10px;margin-top: 20px;margin-left: 14px">
                <div style="height: 7px"></div>
                <div @click="goAnchor('#其他地区',1)" style="margin-left: 15px;width: 300px;cursor: pointer;">
                  <span class="weekly-font-table">本周（{{weeklyInfo.tableDetailRespVO.beginDate}}-{{weeklyInfo.tableDetailRespVO.endDate}}），重点关注地区之外的{{weeklyInfo.tableDetailRespVO.subNameStr}}收到的罚单数合计
                    <span  style="font-family: SourceHanSansCN-Regular;font-weight: 900;color: #1472FF;text-decoration:underline">{{weeklyInfo.tableDetailRespVO.punNum}}</span>张</span>
                </div>
                <div style="height: 8px"></div>
              </div>
              <div style="margin-top: 10px;margin-left: 20px">
                <span class="weekly-font-table-beizhu">点击蓝色数字查看该地区处罚清单</span>
              </div>

              <div style="height: 20px"></div>
            </div>
          </div>

          <!--重点关注模块-->
          <div v-if="weeklyInfo.haveSpFlag">
            <div  class="weekly-div-background-s" >
              <div style="text-align: center">
                <img src="@/assets/image/weekly/biaoti.png" alt=""/>
                <span class="weekly-font-title"> 特别关注 </span>
                <img src="@/assets/image/weekly/biaoti.png" alt=""/>
              </div>
            </div>
            <div  class="weekly-div-background-z" >
              <div>
                <div>
                  <div v-for="item1 in weeklyInfo.djSplList" style="margin-bottom: 10px;display: flex;">

                    <div style="margin-left: 21px">
                      <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                    </div>

                    <div v-if="item1.assList.length >= 2 || (item1.assList.length == 1 &&  item1.otherLink != '')"  >
                      <el-popover
                          width="250"
                          placement="bottom"
                          popper-class="custom-popover"
                          trigger="click">
                        <div style="width: 250px;">
                          <div style="text-align: center;font-size: 15px;cursor: pointer;color: #658BFE;" @click="punishSelectDetail(item2.punId)" v-for="item2,index in item1.assList">
                            <span style="line-height: 40px; ">{{item2.fileNum}}</span>
                            <div v-if=" item1.assList.length != index+1" style="height: 1px;width: 250px;background-color: #E2E5EB"></div>
                          </div>
                          <div v-if="item1.otherLink != ''" style="text-align: center;font-size: 15px;cursor: pointer;color: #658BFE;" @click="punishOtherDetail(item1.otherLink)" >
                            <div style="height: 1px;width: 250px;background-color: #E2E5EB"></div>
                            <span style="line-height: 40px; ">{{item1.otherTitle}}</span>
                          </div>
                        </div>
                        <div v-if="item1.assList.length >= 0" slot="reference" @click="">
                          <div style="width: 284px;font-size: 14px;font-weight: 400;line-height: 20px;font-family: 思源黑体;color: black;margin-left: 2.5px;cursor: pointer;" v-html="item1.content" >
                          </div>
                        </div>
                      </el-popover>
                    </div>
                    <div v-else>
                      <div @click="punishSpDetail(item1)">
                        <div style="width: 284px;font-size: 14px;font-weight: 400;line-height: 20px;font-family: 思源黑体;margin-left: 2.5px;cursor: pointer;" v-html="item1.content" >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="height: 5px"></div>
                </div>

              </div>
              <div style="margin-left: 15px;margin-top: -5px">
                <span class="weekly-font-table-beizhu">点击特别关注内容查看详细信息</span>
              </div>
            </div>
            <div  class="weekly-div-background-x" ></div>
            <div  style="height: 20px" ></div>
          </div>

          <div v-if="weeklyInfo.config.wcfFlag">
            <div v-if="weeklyInfo.noDataVO.showFlag && weeklyInfo.noDataVO.rowNum == 1" class="weekly-div-background-two">
              <div style="height: 19px"></div>
              <div style="text-align: left;width: 320px;display: flex">
                <div style="width: 21.5px; flex: 0 0 21.5px; height: 9px" >
                </div>
                <div>
                  <span class="weekly-font-no-title"> {{weeklyInfo.noDataVO.titleName}} </span>
                </div>
              </div>
              <div style="height: 15px"></div>
              <div style="text-align: left">
                <span class="weekly-font-no-content"> 本周无针对人身险公司、保险代理及经纪机构 </span>
              </div>
              <div style="text-align: left">
                <span class="weekly-font-no-content"> 的处罚 </span>
              </div>

              <div>
                <div style="height: 10px"></div>
                <span class="weekly-font-no-title" style="margin-left: 21.5px"> 中国人民银行 </span>
                <div style="height: 5px"></div>
              </div>
              <div style="text-align: left">
                <span v-if="weeklyInfo.bankTableDetailRespVO.punNum == 0" class="weekly-font-no-content"> 本周无针对保险机构的处罚 </span>
                <span v-else class="weekly-font-no-content"> 本周对保险机构开出<span  @click="goAnchor('#人行' ,111)"  style="text-decoration: underline;color: #3a8ee6;font-weight: 700;cursor: pointer"> {{weeklyInfo.bankTableDetailRespVO.punNum}} </span> 张罚单 </span>
                <div style="height: 15px"></div>
              </div>
            </div>
            <div v-if="weeklyInfo.noDataVO.showFlag && weeklyInfo.noDataVO.rowNum == 2" class="weekly-div-background-two">
              <div style="height: 19px"></div>
              <div style="text-align: left;width: 320px;display: flex">
                <div style="width: 21.5px; flex: 0 0 21.5px; height: 9px" >
                </div>
                <div>
                  <span class="weekly-font-no-title"> {{weeklyInfo.noDataVO.titleName}} </span>
                </div>
              </div>
              <div style="height: 15px"></div>
              <div style="text-align: left">
                <span class="weekly-font-no-content"> {{weeklyInfo.noDataVO.content}} </span>
              </div>
            </div>
            <div v-if="weeklyInfo.noDataVO.showFlag && weeklyInfo.noDataVO.rowNum == 3" class="weekly-div-background-three">
              <div style="height: 19px"></div>
              <div style="text-align: left;width: 320px;display: flex">
                <div style="width: 21.5px; flex: 0 0 21.5px; height: 9px" >
                </div>
                <div>
                  <span class="weekly-font-no-title-three"> {{weeklyInfo.noDataVO.titleName}} </span>
                </div>
              </div>
              <div style="height: 15px"></div>
              <div style="text-align: left">
                <span class="weekly-font-no-content"> {{weeklyInfo.noDataVO.content}} </span>
              </div>
            </div>
            <div v-if="weeklyInfo.noDataVO.showFlag && weeklyInfo.noDataVO.rowNum == 4" class="weekly-div-background-four">
              <div style="height: 19px"></div>
              <div style="text-align: left;width: 320px;display: flex">
                <div style="width: 21.5px; flex: 0 0 21.5px; height: 9px" >
                </div>
                <div>
                  <span class="weekly-font-no-title-four"> {{weeklyInfo.noDataVO.titleName}} </span>
                </div>
              </div>
              <div style="height: 15px"></div>
              <div style="text-align: left">
                <span class="weekly-font-no-content"> {{weeklyInfo.noDataVO.content}} </span>
              </div>
            </div>



          </div>

          <div v-for="item,index in punishList" :id="item.subName" >
            <div  class="weekly-div-background-s" >
              <div v-if="index == 0 && weeklyInfo.tenantId != 11" style="padding-top: 10px;margin-left: 20px;margin-bottom: -10px">
                <span class="weekly-font-table-beizhu">点击处罚信息，查看罚单详情</span>
              </div>
              <div style="text-align: center">
                <img src="@/assets/image/weekly/biaoti.png" alt=""/>
                <span class="weekly-font-title"> {{item.subName}} </span>
                <img src="@/assets/image/weekly/biaoti.png" alt=""/>
              </div>
            </div>
            <div  class="weekly-div-background-z" >
              <div>
                <div v-if="item.flag && item.subName == '其他地区'">
                  <div v-show="otherFlag">
                    <div v-if="item.rsxFiveDetailList != null">
                      <div class="weekly-punish-div-group" >
                        <span class="weekly-punish-div-group-font">寿险</span>
                      </div>
                      <div v-for="item1 in item.rsxFiveDetailList" @click="punishDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                        <div style="margin-left: 21px">
                          <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                        </div>
                        <div style="width: 284px;font-size: 14px;line-height: 20px;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                          <img src="@/assets/image/weekly/sxjt.png" style="width: 9.5px;height: 9.5px" alt=""/>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.ccxFiveDetailList != null">
                      <div class="weekly-punish-div-group" >
                        <span class="weekly-punish-div-group-font">财险</span>
                      </div>
                      <div v-for="item1 in item.ccxFiveDetailList" @click="punishDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                        <div style="margin-left: 21px">
                          <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                        </div>
                        <div style="width: 284px;font-size: 14px;line-height: 20px;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                          <img src="@/assets/image/weekly/sxjt.png" style="width: 9.5px;height: 9.5px" alt=""/>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.zjFiveDetailList != null">
                      <div class="weekly-punish-div-group" >
                        <span class="weekly-punish-div-group-font">中介</span>
                      </div>
                      <div v-for="item1 in item.zjFiveDetailList" @click="punishDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                        <div style="margin-left: 21px">
                          <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                        </div>
                        <div style="width: 284px;font-size: 14px;line-height: 20px;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                          <img src="@/assets/image/weekly/sxjt.png" style="width: 9.5px;height: 9.5px" alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="!otherFlag">
                    <div v-if="item.rsxDetailList != null">
                      <div class="weekly-punish-div-group" >
                        <span class="weekly-punish-div-group-font">寿险</span>
                      </div>
                      <div v-for="item1 in item.rsxDetailList" @click="punishDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                        <div style="margin-left: 21px">
                          <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                        </div>
                        <div style="width: 284px;font-size: 14px;line-height: 20px;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                          <img src="@/assets/image/weekly/sxjt.png" style="width: 9.5px;height: 9.5px" alt=""/>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.ccxDetailList != null">
                      <div class="weekly-punish-div-group" >
                        <span class="weekly-punish-div-group-font">财险</span>
                      </div>
                      <div v-for="item1 in item.ccxDetailList" @click="punishDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                        <div style="margin-left: 21px">
                          <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                        </div>
                        <div style="width: 284px;font-size: 14px;line-height: 20px;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                          <img src="@/assets/image/weekly/sxjt.png" style="width: 9.5px;height: 9.5px" alt=""/>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.zjDetailList != null">
                      <div class="weekly-punish-div-group" >
                        <span class="weekly-punish-div-group-font">中介</span>
                      </div>
                      <div v-for="item1 in item.zjDetailList" @click="punishDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                        <div style="margin-left: 21px">
                          <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                        </div>
                        <div style="width: 284px;font-size: 14px;line-height: 20px;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                          <img src="@/assets/image/weekly/sxjt.png" style="width: 9.5px;height: 9.5px" alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="item.rsxDetailList != null">
                    <div class="weekly-punish-div-group" >
                      <span class="weekly-punish-div-group-font">寿险</span>
                    </div>
                    <div v-for="item1 in item.rsxDetailList" @click="punishDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                      <div style="margin-left: 21px">
                        <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                      </div>
                      <div style="width: 284px;font-size: 14px;line-height: 20px;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                        <img src="@/assets/image/weekly/sxjt.png" style="width: 9.5px;height: 9.5px" alt=""/>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.ccxDetailList != null">
                    <div class="weekly-punish-div-group" >
                      <span class="weekly-punish-div-group-font">财险</span>
                    </div>
                    <div v-for="item1 in item.ccxDetailList" @click="punishDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                      <div style="margin-left: 21px">
                        <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                      </div>
                      <div style="width: 284px;font-size: 14px;line-height: 20px;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                        <img src="@/assets/image/weekly/sxjt.png" style="width: 9.5px;height: 9.5px" alt=""/>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.zjDetailList != null">

                    <div class="weekly-punish-div-group" >
                      <span class="weekly-punish-div-group-font">中介</span>
                    </div>
                    <div v-for="item1 in item.zjDetailList" @click="punishDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                      <div style="margin-left: 21px">
                        <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                      </div>
                      <div style="width: 284px;font-size: 14px;line-height: 20px;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                        <img src="@/assets/image/weekly/sxjt.png" style="width: 9.5px;height: 9.5px" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="index != punishList.length - 1 " style="height: 5px"></div>
                <div v-if="index == punishList.length - 1 && !otherFlag" style="height: 5px"></div>
                <div v-if="index == punishList.length - 1 && otherFlag" style="text-align: center;height: 40px;cursor: pointer;" @click="moreOther">
                  <span class="weekly-font-more">加载更多</span>
                </div>
              </div>
            </div>
            <div  class="weekly-div-background-x" >
            </div>
          </div>

          <!--人行模块-->
          <div style="" id="人行" v-if="weeklyInfo.bankTableDetailRespVO.punNum != 0">
            <div  class="weekly-div-background-s" >
              <div style="text-align: center">
                <img src="@/assets/image/weekly/biaoti.png" alt=""/>
                <span class="weekly-font-title"> 中国人民银行及分行 </span>
                <img src="@/assets/image/weekly/biaoti.png" alt=""/>
              </div>
            </div>
            <div  class="weekly-div-background-z" >
              <div>
                <div v-for="item1 in weeklyInfo.bankDetailRespVO.bankDetailList" @click="punishBankDetail(item1.punishId)" style="margin-bottom: 10px;display: flex;">
                  <div style="margin-left: 21px">
                    <img src="@/assets/image/weekly/yuan-zi.png" style="width: 9px; flex: 0 0 11.5px; height: 9px" alt=""/>
                  </div>
                  <div style="width: 284px;font-size: 14px;line-height: 20px;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                    <img src="@/assets/image/weekly/sxjt.png" style="width: 9.5px;height: 9.5px" alt=""/>
                  </div>
                </div>
                <div style="height: 5px"></div>
              </div>
            </div>
            <div  class="weekly-div-background-x" ></div>
            <div  style="height: 20px" ></div>
          </div>

        </div>


        <div v-show="!clickFlag">
          <img style="position: absolute;z-index: 999;left: 165px;top: 285.5px;width: 28.5px;height: 28.5px" src="@/assets/image/weekly/shouzhi.png" alt=""/>
          <div class="weekly-tags-right"></div>
          <span @click="filtrate(true)"  class="weekly-span-style3"> 本周处罚详情</span>
          <span class="weekly-span-style4"> {{year}}年处罚分析</span>
          <img  style="position: absolute;z-index:-999;left: 7px;top: 323px;width: 361px;height: 541.5px" src="@/assets/image/weekly/di-right.png" alt=""/>
          <div style="height: 250px"></div>



          <div v-if="weeklyInfo.haveRsxFlag">
            <div :class="weeklyInfo.oneRsxFlag? 'weekly-punish-div-fxbg1': 'weekly-punish-div-fxbg'">
              <div style="height: 22px"></div>
              <span class="weekly-punish-title1"> 人身险公司监管处罚分析</span>
            </div>
            <div class="weekly-punish-div-background">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 总体处罚情况 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">{{year}}年截至{{weeklyInfo.month}}月{{weeklyInfo.day}}日，金监局系统共向人身险公司开出罚单<span style="color: #EF7C00">{{weeklyInfo.rsxReport.sumPun}}</span>张，其中机构罚单<span style="color: #EF7C00">{{weeklyInfo.rsxReport.comPun}}</span>张，个人罚单<span style="color: #EF7C00">{{weeklyInfo.rsxReport.perPun}}</span>张；</span>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">处罚金额<span style="color: #EF7C00">{{weeklyInfo.rsxReport.sumMoney}}</span>万元，机构处罚金额<span style="color: #EF7C00">{{weeklyInfo.rsxReport.comMoney}}</span>万元，个人处罚金额<span style="color: #EF7C00">{{weeklyInfo.rsxReport.perMoney}}</span>万元。</span>
              </div>

            </div>
            <div class="weekly-punish-div-background-z"></div>
            <div class="weekly-punish-div1" >
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 处罚人员分析 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div>
                <div style="width: 310px;margin-left: 20px">
                  <span class="weekly-font-ensemble">共<span style="color: #EF7C00">{{weeklyInfo.rsxReport.perPunNum}}</span>张罚单涉及或关联到个人处罚。</span>
                </div>
                <div style="width: 310px;margin-left: 20px;margin-top: 8px;display: flex;align-items: center">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">高管层{{weeklyInfo.rsxReport.gzPun}}人，罚款{{weeklyInfo.rsxReport.gzMoney}}万元；</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">部门级别{{weeklyInfo.rsxReport.bmPun}}人，罚款{{weeklyInfo.rsxReport.bmMoney}}万元；</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">经办人员{{weeklyInfo.rsxReport.jbryPun}}人，罚款 {{weeklyInfo.rsxReport.jbryMoney}}万元。</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">其他人员{{weeklyInfo.rsxReport.qtPun}}人，罚款 {{weeklyInfo.rsxReport.qtMoney}}万元。</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <span class="weekly-font-person-beizhu">( 其他人员: 指其他职务或者未披露职务的人员)</span>
                </div>
              </div>


              <div style="display: flex; margin-top: 20px;align-items: center">
                <el-progress :show-text = "true" :format="setItemText('rsx_gg_p')" :text-inside="true" :stroke-width="16" color="#658BFE" text-color = "#433F5E" style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.rsxProgressVO.ggPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">高管层 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('rsx_gg_m')"  :text-inside="true" color="#FD6580" :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.rsxProgressVO.ggMoney"></el-progress>
              </div>


              <div style="display: flex;align-items: center;margin-top: 10px;">
                <el-progress :show-text = "true" :text-inside="true" :format="setItemText('rsx_bm_p')"  :stroke-width="16" color="#658BFE"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.rsxProgressVO.bmPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">部门级别 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('rsx_bm_m')"  :text-inside="true" color="#FD6580"   :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.rsxProgressVO.bmMoney"></el-progress>
              </div>


              <div style="display: flex;align-items: center;margin-top: 10px;">
                <el-progress :show-text = "true" :text-inside="true" :format="setItemText('rsx_jb_p')"   :stroke-width="16" color="#658BFE"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.rsxProgressVO.jbPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">经办人员 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('rsx_jb_m')"  :text-inside="true" color="#FD6580"  :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.rsxProgressVO.jbMoney"></el-progress>
              </div>

              <div style="display: flex;align-items: center;margin-top: 10px;">
                <el-progress :show-text = "true" :text-inside="true"  :format="setItemText('rsx_qt_p')"  :stroke-width="16" color="#658BFE"   style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.rsxProgressVO.qtPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">其他 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('rsx_qt_m')"  :text-inside="true" color="#FD6580"  :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.rsxProgressVO.qtMoney"></el-progress>
              </div>


              <div style="height: 5px"></div>
            </div>
            <div class="weekly-punish-div-background-z"></div>
            <div class="weekly-punish-div2">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 处罚机构分析 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">重点关注机构中{{weeklyInfo.rsxSubVO.maxMoneyName}}处罚金额最高，累计高达<span style="color: #EF7C00">{{weeklyInfo.rsxSubVO.maxMoney}}</span>万；{{weeklyInfo.rsxSubVO.maxPunName}}罚单数量最多，累计罚单<span style="color: #EF7C00">{{weeklyInfo.rsxSubVO.maxPunNumber}}</span>张。</span>
              </div>

              <div id="punishjg2" style="height: 230px;width: 340px;margin-top: 20px"></div>

            </div>
            <div v-if="weeklyInfo.config.yybfFlag && weeklyInfo.rsxYySubVO.showFlag">
              <div class="weekly-punish-div-background-z"></div>
              <div class="weekly-punish-div-yybf">
                <div style="text-align: center">
                  <img src="@/assets/image/weekly/title-left.png" alt=""/>
                  <span class="weekly-ensemble-title"> 亿元保费处罚分析 </span>
                  <img src="@/assets/image/weekly/title-right.png" alt=""/>
                </div>
                <div style="width: 310px;margin-left: 20px">
                  <span class="weekly-font-ensemble">{{weeklyInfo.month == 1 ? year - 1 : year}}年截至{{weeklyInfo.month == 1 ? 12 : weeklyInfo.month - 1}}月，重点关注机构中{{weeklyInfo.rsxYySubVO.maxPunName}}亿元保费罚单数<span style="color: #EF7C00">{{weeklyInfo.rsxYySubVO.maxPunNumber}}</span>张，排名第一；{{weeklyInfo.rsxYySubVO.maxMoneyName}}亿元保费罚款总额<span style="color: #EF7C00">{{weeklyInfo.rsxYySubVO.maxMoney}}</span>万，排名第一；</span>
                </div>
                <div style="width: 310px;margin-left: 20px;margin-top: 5px">
                  <span class="weekly-font-person-beizhu">注：原保险保费收入 为官网披露的上一年度同期数据。</span>
                </div>

                <div id="rsxyy" style="height: 230px;width: 340px;margin-top: 20px"></div>

              </div>
            </div>
            <div class="weekly-punish-div-background-z"></div>
            <div class="weekly-punish-div3">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 处罚事由排行 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div style="width: 310px;margin-left: 20px">
              <span class="weekly-font-ensemble">
                人身险公司处罚频次前三的事由包括：
                {{weeklyInfo.rsxReasonVO.nameList[weeklyInfo.rsxReasonVO.nameList.length-1]}}共处罚<span style="color: #EF7C00">{{weeklyInfo.rsxReasonVO.reasonNumList[weeklyInfo.rsxReasonVO.reasonNumList.length - 1]}}</span>次；
                {{weeklyInfo.rsxReasonVO.nameList[weeklyInfo.rsxReasonVO.nameList.length-2]}}共处罚<span style="color: #EF7C00">{{weeklyInfo.rsxReasonVO.reasonNumList[weeklyInfo.rsxReasonVO.reasonNumList.length - 2]}}</span>次；
                {{weeklyInfo.rsxReasonVO.nameList[weeklyInfo.rsxReasonVO.nameList.length-3]}}共处罚<span style="color: #EF7C00">{{weeklyInfo.rsxReasonVO.reasonNumList[weeklyInfo.rsxReasonVO.reasonNumList.length - 3]}}</span>次。
              </span>
              </div>
              <div id="reason1" :style="{'height': weeklyInfo.rsxReasonVO.maxHeight + 'px','width': '330px','margin-top': '-20px'}"></div>
            </div>
          </div>
          <div v-if="weeklyInfo.haveCcxFlag">
            <div :class="weeklyInfo.oneCcxFlag? 'weekly-punish-div-fxbg1': 'weekly-punish-div-fxbg'">
              <div style="height: 22px"></div>
              <span class="weekly-punish-title1"> 财产险公司监管处罚分析</span>
            </div>
            <div class="weekly-punish-div-background">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 总体处罚情况 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">{{year}}年截至{{weeklyInfo.month}}月{{weeklyInfo.day}}日，金监局系统共向财产险公司开出罚单<span style="color: #EF7C00">{{weeklyInfo.ccxReport.sumPun}}</span>张，其中机构罚单<span style="color: #EF7C00">{{weeklyInfo.ccxReport.comPun}}</span>张，个人罚单<span style="color: #EF7C00">{{weeklyInfo.ccxReport.perPun}}</span>张；</span>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">处罚金额<span style="color: #EF7C00">{{weeklyInfo.ccxReport.sumMoney}}</span>万元，机构处罚金额<span style="color: #EF7C00">{{weeklyInfo.ccxReport.comMoney}}</span>万元，个人处罚金额<span style="color: #EF7C00">{{weeklyInfo.ccxReport.perMoney}}</span>万元。</span>
              </div>

            </div>
            <div class="weekly-punish-div-background-z"></div>
            <div class="weekly-punish-div1" >
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 处罚人员分析 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div>
                <div style="width: 310px;margin-left: 20px">
                  <span class="weekly-font-ensemble">共<span style="color: #EF7C00">{{weeklyInfo.ccxReport.perPunNum}}</span>张罚单涉及或关联到个人处罚。</span>
                </div>
                <div style="width: 310px;margin-left: 20px;margin-top: 8px;display: flex;align-items: center">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">高管层{{weeklyInfo.ccxReport.gzPun}}人，罚款{{weeklyInfo.ccxReport.gzMoney}}万元；</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">部门级别{{weeklyInfo.ccxReport.bmPun}}人，罚款{{weeklyInfo.ccxReport.bmMoney}}万元；</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">经办人员{{weeklyInfo.ccxReport.jbryPun}}人，罚款 {{weeklyInfo.ccxReport.jbryMoney}}万元。</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">其他人员{{weeklyInfo.ccxReport.qtPun}}人，罚款 {{weeklyInfo.ccxReport.qtMoney}}万元。</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <span class="weekly-font-person-beizhu">( 其他人员: 指其他职务或者未披露职务的人员)</span>
                </div>
              </div>


              <div style="display: flex; margin-top: 20px;align-items: center">
                <el-progress :show-text = "true" :format="setItemText('ccx_gg_p')" :text-inside="true" :stroke-width="16" color="#658BFE" text-color = "#433F5E" style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.ccxProgressVO.ggPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">高管层 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('ccx_gg_m')"  :text-inside="true" color="#FD6580" :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.ccxProgressVO.ggMoney"></el-progress>
              </div>


              <div style="display: flex;align-items: center;margin-top: 10px;">
                <el-progress :show-text = "true" :text-inside="true" :format="setItemText('ccx_bm_p')"  :stroke-width="16" color="#658BFE"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.ccxProgressVO.bmPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">部门级别 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('ccx_bm_m')"  :text-inside="true" color="#FD6580"   :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.ccxProgressVO.bmMoney"></el-progress>
              </div>


              <div style="display: flex;align-items: center;margin-top: 10px;">
                <el-progress :show-text = "true" :text-inside="true" :format="setItemText('ccx_jb_p')"   :stroke-width="16" color="#658BFE"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.ccxProgressVO.jbPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">经办人员 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('ccx_jb_m')"  :text-inside="true" color="#FD6580"  :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.ccxProgressVO.jbMoney"></el-progress>
              </div>

              <div style="display: flex;align-items: center;margin-top: 10px;">
                <el-progress :show-text = "true" :text-inside="true"  :format="setItemText('ccx_qt_p')"  :stroke-width="16" color="#658BFE"   style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.ccxProgressVO.qtPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">其他 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('ccx_qt_m')"  :text-inside="true" color="#FD6580"  :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.ccxProgressVO.qtMoney"></el-progress>
              </div>


              <div style="height: 5px"></div>
            </div>
            <div class="weekly-punish-div-background-z"></div>
            <div class="weekly-punish-div2">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 处罚机构分析 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">重点关注机构中{{weeklyInfo.ccxSubVO.maxMoneyName}}处罚金额最高，累计高达<span style="color: #EF7C00">{{weeklyInfo.ccxSubVO.maxMoney}}</span>万；{{weeklyInfo.ccxSubVO.maxPunName}}罚单数量最多，累计罚单<span style="color: #EF7C00">{{weeklyInfo.ccxSubVO.maxPunNumber}}</span>张。</span>
              </div>
              <div id="punishjg3" style="height: 230px;width: 340px;margin-top: 20px"></div>
            </div>
            <div v-if="weeklyInfo.config.yybfFlag && weeklyInfo.ccxYySubVO.showFlag">
              <div class="weekly-punish-div-background-z"></div>
              <div class="weekly-punish-div-yybf">
                <div style="text-align: center">
                  <img src="@/assets/image/weekly/title-left.png" alt=""/>
                  <span class="weekly-ensemble-title"> 亿元保费处罚分析 </span>
                  <img src="@/assets/image/weekly/title-right.png" alt=""/>
                </div>
                <div style="width: 310px;margin-left: 20px">
                  <span class="weekly-font-ensemble">{{weeklyInfo.month == 1 ? year - 1 : year}}年截至{{weeklyInfo.month == 1 ? 12 : weeklyInfo.month - 1}}月，重点关注机构中{{weeklyInfo.ccxYySubVO.maxPunName}}亿元保费罚单数<span style="color: #EF7C00">{{weeklyInfo.ccxYySubVO.maxPunNumber}}</span>张，排名第一；{{weeklyInfo.ccxYySubVO.maxMoneyName}}亿元保费罚款总额<span style="color: #EF7C00">{{weeklyInfo.ccxYySubVO.maxMoney}}</span>万，排名第一；</span>
                </div>
                <div style="width: 310px;margin-left: 20px;margin-top: 5px">
                  <span class="weekly-font-person-beizhu">注：原保险保费收入 为官网披露的上一年度同期数据。</span>
                </div>

                <div id="ccxyy" style="height: 230px;width: 340px;margin-top: 20px"></div>

              </div>
            </div>
            <div class="weekly-punish-div-background-z"></div>
            <div class="weekly-punish-div3">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 处罚事由排行 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div style="width: 310px;margin-left: 20px">
              <span class="weekly-font-ensemble">
                财产险公司处罚频次前三的事由包括：
                {{weeklyInfo.ccxReasonVO.nameList[weeklyInfo.ccxReasonVO.nameList.length-1]}}共处罚<span style="color: #EF7C00">{{weeklyInfo.ccxReasonVO.reasonNumList[weeklyInfo.ccxReasonVO.reasonNumList.length - 1]}}</span>次；
                {{weeklyInfo.ccxReasonVO.nameList[weeklyInfo.ccxReasonVO.nameList.length-2]}}共处罚<span style="color: #EF7C00">{{weeklyInfo.ccxReasonVO.reasonNumList[weeklyInfo.ccxReasonVO.reasonNumList.length - 2]}}</span>次；
                {{weeklyInfo.ccxReasonVO.nameList[weeklyInfo.ccxReasonVO.nameList.length-3]}}共处罚<span style="color: #EF7C00">{{weeklyInfo.ccxReasonVO.reasonNumList[weeklyInfo.ccxReasonVO.reasonNumList.length - 3]}}</span>次。
              </span>
              </div>
              <div id="reason3" :style="{'height': weeklyInfo.ccxReasonVO.maxHeight + 'px','width': '330px','margin-top': '-20px'}"></div>
            </div>
          </div>
          <div v-if="weeklyInfo.haveZjFlag">
            <div :class="weeklyInfo.oneZjFlag? 'weekly-punish-div-fxbg1': 'weekly-punish-div-fxbg'">
              <div style="height: 22px"></div>
              <span class="weekly-punish-title"> 中介机构监管处罚分析</span>
            </div>
            <div class="weekly-punish-div-background">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 总体处罚情况 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">{{year}}年截至{{weeklyInfo.month}}月{{weeklyInfo.day}}日，金监局系统共向中介机构开出罚单<span style="color: #EF7C00">{{weeklyInfo.zjReport.sumPun}}</span>张，其中机构罚单<span style="color: #EF7C00">{{weeklyInfo.zjReport.comPun}}</span>张，个人罚单<span style="color: #EF7C00">{{weeklyInfo.zjReport.perPun}}</span>张；</span>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">处罚金额<span style="color: #EF7C00">{{weeklyInfo.zjReport.sumMoney}}</span>万元，机构处罚金额<span style="color: #EF7C00">{{weeklyInfo.zjReport.comMoney}}</span>万元，个人处罚金额<span style="color: #EF7C00">{{weeklyInfo.zjReport.perMoney}}</span>万元。</span>
              </div>
            </div>
            <div class="weekly-punish-div-background-z"></div>
            <div class="weekly-punish-div1">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 处罚人员分析 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div>
                <div style="width: 310px;margin-left: 20px">
                  <span class="weekly-font-ensemble">共<span style="color: #EF7C00">{{weeklyInfo.zjReport.perPunNum}}</span>张罚单涉及或关联到个人处罚。</span>
                </div>
                <div style="width: 310px;margin-left: 20px;margin-top: 8px;display: flex;align-items: center">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">高管层{{weeklyInfo.zjReport.gzPun}}人，罚款{{weeklyInfo.zjReport.gzMoney}}万元；</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">部门级别{{weeklyInfo.zjReport.bmPun}}人，罚款{{weeklyInfo.zjReport.bmMoney}}万元；</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">经办人员{{weeklyInfo.zjReport.jbryPun}}人，罚款 {{weeklyInfo.zjReport.jbryMoney}}万元。</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <img src="@/assets/image/weekly/yuan.png" style="width: 11.5px;height: 10px" alt=""/>
                  <span class="weekly-font-person">其他人员{{weeklyInfo.zjReport.qtPun}}人，罚款 {{weeklyInfo.zjReport.qtMoney}}万元。</span>
                </div>
                <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                  <span class="weekly-font-person-beizhu">( 其他人员: 指其他职务或者未披露职务的人员)</span>
                </div>
              </div>


              <div style="display: flex; margin-top: 20px;align-items: center">
                <el-progress :show-text = "true" :format="setItemText('zj_gg_p')" :text-inside="true" :stroke-width="16" color="#658BFE" text-color = "#433F5E" style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.zjProgressVO.ggPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">高管层 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('zj_gg_m')"  :text-inside="true" color="#FD6580" :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.zjProgressVO.ggMoney"></el-progress>
              </div>


              <div style="display: flex;align-items: center;margin-top: 10px;">
                <el-progress :show-text = "true" :text-inside="true" :format="setItemText('zj_bm_p')"  :stroke-width="16" color="#658BFE"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.zjProgressVO.bmPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">部门级别 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('zj_bm_m')"  :text-inside="true" color="#FD6580"   :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.zjProgressVO.bmMoney"></el-progress>
              </div>


              <div style="display: flex;align-items: center;margin-top: 10px;">
                <el-progress :show-text = "true" :text-inside="true" :format="setItemText('zj_jb_p')"   :stroke-width="16" color="#658BFE"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.zjProgressVO.jbPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">经办人员 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('zj_jb_m')"  :text-inside="true" color="#FD6580"  :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.zjProgressVO.jbMoney"></el-progress>
              </div>

              <div style="display: flex;align-items: center;margin-top: 10px;">
                <el-progress :show-text = "true" :text-inside="true"  :format="setItemText('zj_qt_p')"  :stroke-width="16" color="#658BFE"   style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.zjProgressVO.qtPerson"></el-progress>
                <div class="weekly-punish-div-person">
                  <div style="height: 2px"></div>
                  <span class="weekly-punish-div-person-font">其他 </span>
                </div>
                <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('zj_qt_m')"  :text-inside="true" color="#FD6580"  :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.zjProgressVO.qtMoney"></el-progress>
              </div>


              <div style="height: 5px"></div>
            </div>
            <div class="weekly-punish-div-background-z"></div>
            <div class="weekly-punish-div2">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 处罚机构分析 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">重点关注机构中{{weeklyInfo.zjSubVO.maxMoneyName}}处罚金额最高，累计高达<span style="color: #EF7C00">{{weeklyInfo.zjSubVO.maxMoney}}</span>万；{{weeklyInfo.zjSubVO.maxPunName}}罚单数量最多，累计罚单<span style="color: #EF7C00">{{weeklyInfo.zjSubVO.maxPunNumber}}</span>张。</span>
              </div>
              <div id="punishjg1" style="height: 230px;width: 340px;margin-top: 20px"></div>
            </div>
            <div class="weekly-punish-div-background-z"></div>
            <div class="weekly-punish-div3">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 处罚事由排行 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>

              <div style="width: 310px;margin-left: 20px">
              <span class="weekly-font-ensemble">
                中介机构处罚频次前三的事由包括：
                {{weeklyInfo.zjReasonVO.nameList[weeklyInfo.zjReasonVO.nameList.length-1]}}共处罚<span style="color: #EF7C00">{{weeklyInfo.zjReasonVO.reasonNumList[weeklyInfo.zjReasonVO.reasonNumList.length - 1]}}</span>次；
                {{weeklyInfo.zjReasonVO.nameList[weeklyInfo.zjReasonVO.nameList.length-2]}}共处罚<span style="color: #EF7C00">{{weeklyInfo.zjReasonVO.reasonNumList[weeklyInfo.zjReasonVO.reasonNumList.length - 2]}}</span>次；
                {{weeklyInfo.zjReasonVO.nameList[weeklyInfo.zjReasonVO.nameList.length-3]}}共处罚<span style="color: #EF7C00">{{weeklyInfo.zjReasonVO.reasonNumList[weeklyInfo.zjReasonVO.reasonNumList.length - 3]}}</span>次。
              </span>
              </div>

              <div id="reason2" :style="{'height': weeklyInfo.zjReasonVO.maxHeight + 'px','width': '330px','margin-top': '-20px'}"></div>
            </div>
          </div>

          <div>
            <div class="weekly-punish-div-fxbg">
              <div style="height: 22px"></div>
              <span class="weekly-punish-title"> 中国人民银行及分行</span>
            </div>
            <div class="weekly-punish-div-background">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 总体处罚情况 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">{{year}}年截至{{weeklyInfo.month}}月{{weeklyInfo.day}}日，人行系统共向保险公司开出罚单<span style="color: #EF7C00">{{weeklyInfo.reportBank.sumPun}}</span>张，其中机构罚单<span style="color: #EF7C00">{{weeklyInfo.reportBank.comPun}}</span>张，个人罚单<span style="color: #EF7C00">{{weeklyInfo.reportBank.perPun}}</span>张；</span>
              </div>
              <div style="width: 310px;margin-left: 20px">
                <span class="weekly-font-ensemble">处罚金额<span style="color: #EF7C00">{{weeklyInfo.reportBank.sumMoney}}</span>万元，机构处罚金额<span style="color: #EF7C00">{{weeklyInfo.reportBank.comMoney}}</span>万元，个人处罚金额<span style="color: #EF7C00">{{weeklyInfo.reportBank.perMoney}}</span>万元。</span>
              </div>
            </div>
            <div class="weekly-punish-div-background-z"></div>
            <div class="weekly-bank-punish-div1">
              <div style="text-align: center">
                <img src="@/assets/image/weekly/title-left.png" alt=""/>
                <span class="weekly-ensemble-title"> 处罚机构类型情况 </span>
                <img src="@/assets/image/weekly/title-right.png" alt=""/>
              </div>
              <div class="regional-table-bank">
                <div class="regional-table-bank-bankground">
                  <el-table
                      :data="weeklyInfo.orgList"
                      border
                      :row-style="{height: 30+'px',background:'#E5F2FD'}"
                      :cell-style="{padding:0+'px','text-align':'center',background: 'transparent !important','font-size': '14px','font-family': 'Source Han Sans CN','font-weight': 'bold',color: '#15233B 100%','border-color':'#FFFFFF'}"
                      :header-cell-style="{height: 30+'px',padding:0+'px',background:'#2966DF','text-align':'center','font-size': '14px','font-family': 'Source Han Sans CN','font-weight': 'bold',color: '#FFFFFF','border-color':'#FFFFFF'}"
                      class="weekly-el-table-bk"
                  >
                    <el-table-column prop="orgName" label="机构类型" width="108">
                      <template slot-scope="scope">
                        <div style="color:rgb(0, 0, 0);">{{ scope.row.orgName }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="punNum" label="罚单数（张）" width="103">
                      <template slot="header">
                        <div>
                          罚单数<br>
                          <span style="">（张）</span>
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <div  style="color:rgb(0, 0, 0);">{{ scope.row.punNum }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="punMoney"  label="罚款金额   （万元）" width="108">
                      <template slot="header">
                        <div>
                          罚款金额<br>
                          <span style="">（万元）</span>
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <div style="color:rgb(0, 0, 0);">{{ scope.row.punMoney }}</div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="weekly-tags-boder"></div>
        <div style="height: 75px"></div>
        <img  style="position: absolute;bottom: 0px;z-index:-999;margin-top: 20px;left: 58.5px;width: 262px;height: 98.85px" src="@/assets/image/weekly/dibu.png" alt=""/>
        <span  style="position: absolute;bottom: 40px;z-index:-998;left: 90px;color: #FFFFFF;font-size: 14px">注: 统计数据以监管披露时间为准</span>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  import { getWeeklyDetailApi,getYearDetailApi,getWeekDetailApi} from "@/api/weeklyApi";
  export default {
    name: 'report',
    data() {
      return {
        clickFlag:true,
        otherFlag:false,
        year: "",
        weeklyInfo: {
          rsxReport: {},
          zjReport: {},
          ccxReport: {},
          tableDetailRespVO : {},
          bankTableDetailRespVO:{},
          bankDetailRespVO:{},
          reportBank:{},
          config: {},
          rsxSubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          rsxYySubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          zjSubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          ccxSubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          ccxYySubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          rsxReasonVO: {
            nameList: [],
            reasonNumList: [],
            maxLenList: [],
          },
          ccxReasonVO: {
            nameList: [],
            reasonNumList: [],
            maxLenList: [],
          },
          zjReasonVO: {
            nameList: [],
            reasonNumList: [],
            maxLenList: [],
          },
          rsxProgressVO: {},
          zjProgressVO: {},
          ccxProgressVO: {},
          noDataVO: {},
        },
        punishList: [],
      };
    },
    mounted() {
      var date = this.$route.query.date;
      if(date < 20240826){
        this.$router.push({ path: "/weekly/report/v1", query: this.$route.query });
      }
      this.initWeeklyDatas();
    },
    methods: {
      //锚点跳转
      goAnchor(selector,value) {/*参数selector是id选择器（#anchor14）*/
        if(value != 0){
          document.querySelector(selector).scrollIntoView({
            behavior: "smooth"
          });
        }
      },
      setItemText(value) {
        if(value == "rsx_gg_p"){
          return () => {
            return this.weeklyInfo.rsxReport.gzPun + '人'
          }
        }
        if(value == "rsx_gg_m"){
          return () => {
            return this.weeklyInfo.rsxReport.gzMoney + '万元'
          }
        }
        if(value == "rsx_bm_p"){
          return () => {
            return this.weeklyInfo.rsxReport.bmPun + '人'
          }
        }
        if(value == "rsx_bm_m"){
          return () => {
            return this.weeklyInfo.rsxReport.bmMoney + '万元'
          }
        }
        if(value == "rsx_jb_p"){
          return () => {
            return this.weeklyInfo.rsxReport.jbryPun + '人'
          }
        }
        if(value == "rsx_jb_m"){
          return () => {
            return this.weeklyInfo.rsxReport.jbryMoney + '万元'
          }
        }
        if(value == "rsx_qt_p"){
          return () => {
            return this.weeklyInfo.rsxReport.qtPun + '人'
          }
        }
        if(value == "rsx_qt_m"){
          return () => {
            return this.weeklyInfo.rsxReport.qtMoney + '万元'
          }
        }
        if(value == "zj_gg_p"){
          return () => {
            return this.weeklyInfo.zjReport.gzPun + '人'
          }
        }
        if(value == "zj_gg_m"){
          return () => {
            return this.weeklyInfo.zjReport.gzMoney + '万元'
          }
        }
        if(value == "zj_bm_p"){
          return () => {
            return this.weeklyInfo.zjReport.bmPun + '人'
          }
        }
        if(value == "zj_bm_m"){
          return () => {
            return this.weeklyInfo.zjReport.bmMoney + '万元'
          }
        }
        if(value == "zj_jb_p"){
          return () => {
            return this.weeklyInfo.zjReport.jbryPun + '人'
          }
        }
        if(value == "zj_jb_m"){
          return () => {
            return this.weeklyInfo.zjReport.jbryMoney + '万元'
          }
        }
        if(value == "zj_qt_p"){
          return () => {
            return this.weeklyInfo.zjReport.qtPun + '人'
          }
        }
        if(value == "zj_qt_m"){
          return () => {
            return this.weeklyInfo.zjReport.qtMoney + '万元'
          }
        }
        if(value == "ccx_gg_p"){
          return () => {
            return this.weeklyInfo.ccxReport.gzPun + '人'
          }
        }
        if(value == "ccx_gg_m"){
          return () => {
            return this.weeklyInfo.ccxReport.gzMoney + '万元'
          }
        }
        if(value == "ccx_bm_p"){
          return () => {
            return this.weeklyInfo.ccxReport.bmPun + '人'
          }
        }
        if(value == "ccx_bm_m"){
          return () => {
            return this.weeklyInfo.ccxReport.bmMoney + '万元'
          }
        }
        if(value == "ccx_jb_p"){
          return () => {
            return this.weeklyInfo.ccxReport.jbryPun + '人'
          }
        }
        if(value == "ccx_jb_m"){
          return () => {
            return this.weeklyInfo.ccxReport.jbryMoney + '万元'
          }
        }
        if(value == "ccx_qt_p"){
          return () => {
            return this.weeklyInfo.ccxReport.qtPun + '人'
          }
        }
        if(value == "ccx_qt_m"){
          return () => {
            return this.weeklyInfo.ccxReport.qtMoney + '万元'
          }
        }
      },
      initWeeklyDatas(){
        var key = this.$route.query.key;
        var date = this.$route.query.date;
        var dataStatus = this.$route.query.dataStatus;
        getWeeklyDetailApi({
          params: {
            key: key,
            date: date,
            dataStatus: dataStatus,
          },
        }).then((ret) => {
          //处理下划线以及字体颜色
          if(ret.data.data.bankDetailRespVO.bankDetailList != null){
            ret.data.data.bankDetailRespVO.bankDetailList.forEach(item1 =>{
              item1.detailName = item1.detailName + "<span style=\"\">";
              item1.detailName = item1.detailName.replaceAll("@*","<span style=\"padding-bottom:2px; border-bottom:1px solid #2e6adf;color: #2e6adf\">")
              item1.detailName = item1.detailName.replaceAll("*@","</span> <div style=\" width: 5px;height: 13px;margin-right: 3px;display: inline-block \"><div style=\"position: relative\"><i class=\"el-icon-search\" style=\"font-size: 8px;color: #2e6adf;position: absolute;top: 0;right: 0\"></i> </div></div>")
              item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #FF7200\">")
              item1.detailName = item1.detailName.replaceAll("#@","</span>")
              item1.detailName = item1.detailName + "</span>"
              item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 13px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
              item1.detailName = item1.detailName.replaceAll(")","</span></div>")
            })
          }
          ret.data.data.detailRespList.forEach(item =>{
            if(item.rsxDetailList != null){
              item.rsxDetailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","<span style=\"padding-bottom:2px; border-bottom:1px solid #2e6adf;color: #2e6adf\">")
                item1.detailName = item1.detailName.replaceAll("*@","</span> <div style=\" width: 5px;height: 13px;margin-right: 3px;display: inline-block \"><div style=\"position: relative\"><i class=\"el-icon-search\" style=\"font-size: 8px;color: #2e6adf;position: absolute;top: 0;right: 0\"></i> </div></div>")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #FF7200\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>"
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 13px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
              })
            }
            if(item.rsxFiveDetailList != null){
              item.rsxFiveDetailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","<span style=\"padding-bottom:2px; border-bottom:1px solid #2e6adf;color: #2e6adf\">")
                item1.detailName = item1.detailName.replaceAll("*@","</span> <div style=\" width: 5px;height: 13px;margin-right: 3px;display: inline-block \"><div style=\"position: relative\"><i class=\"el-icon-search\" style=\"font-size: 8px;color: #2e6adf;position: absolute;top: 0;right: 0\"></i> </div></div>")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #FF7200\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>";
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 13px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
              })
            }
            if(item.ccxDetailList != null){
              item.ccxDetailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","<span style=\"padding-bottom:2px; border-bottom:1px solid #2e6adf;color: #2e6adf\">")
                item1.detailName = item1.detailName.replaceAll("*@","</span> <div style=\" width: 5px;height: 13px;margin-right: 3px;display: inline-block \"><div style=\"position: relative\"><i class=\"el-icon-search\" style=\"font-size: 8px;color: #2e6adf;position: absolute;top: 0;right: 0\"></i> </div></div>")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #FF7200\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>";
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 13px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
              })
            }
            if(item.ccxFiveDetailList != null){
              item.ccxFiveDetailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","<span style=\"padding-bottom:2px; border-bottom:1px solid #2e6adf;color: #2e6adf\">")
                item1.detailName = item1.detailName.replaceAll("*@","</span> <div style=\" width: 5px;height: 13px;margin-right: 3px;display: inline-block \"><div style=\"position: relative\"><i class=\"el-icon-search\" style=\"font-size: 8px;color: #2e6adf;position: absolute;top: 0;right: 0\"></i> </div></div>")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #FF7200\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>";
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 13px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
              })
            }
            if(item.zjDetailList != null){
              item.zjDetailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","<span style=\"padding-bottom:2px; border-bottom:1px solid #2e6adf;color: #2e6adf\">")
                item1.detailName = item1.detailName.replaceAll("*@","</span> <div style=\" width: 5px;height: 13px;margin-right: 3px;display: inline-block \"><div style=\"position: relative\"><i class=\"el-icon-search\" style=\"font-size: 8px;color: #2e6adf;position: absolute;top: 0;right: 0\"></i> </div></div>")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #FF7200\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>";
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 13px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
              })
            }
            if(item.zjFiveDetailList != null){
              item.zjFiveDetailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","<span style=\"padding-bottom:2px; border-bottom:1px solid #2e6adf;color: #2e6adf\">")
                item1.detailName = item1.detailName.replaceAll("*@","</span> <div style=\" width: 5px;height: 13px;margin-right: 3px;display: inline-block \"><div style=\"position: relative\"><i class=\"el-icon-search\" style=\"font-size: 8px;color: #2e6adf;position: absolute;top: 0;right: 0\"></i> </div></div>")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #FF7200\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>";
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 13px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
              })
            }
          })
          this.punishList = ret.data.data.detailRespList;
          this.weeklyInfo =  ret.data.data;
          this.year = this.weeklyInfo.weekly.year;
          let other = this.punishList[this.punishList.length-1];
          if(other != undefined){
            if(other.subName == '其他地区'){
              if(other.detailList.length > 5){
                this.otherFlag = true
              }
            }
          }

          //画图表
          this.$nextTick(() => {
            if(ret.data.data.haveRsxFlag){
              this.getEcharsReasonRsx(); //人身险处罚事由
              this.getEcharsRsx(); //人身险处罚机构
              if(ret.data.data.config.yybfFlag && ret.data.data.rsxYySubVO != null && ret.data.data.rsxYySubVO.showFlag){
                this.getEcharsYyRsx();//人身险亿元保费
              }
            }
            if(ret.data.data.haveCcxFlag){
              this.getEcharsReasonCcx(); //财产险处罚事由
              this.getEcharsCcx(); //财产险处罚机构
              if(ret.data.data.config.yybfFlag && ret.data.data.ccxYySubVO != null && ret.data.data.ccxYySubVO.showFlag){
                this.getEcharsYyCcx();//财产险亿元保费
              }
            }
            if(ret.data.data.haveZjFlag){
              this.getEcharsReasonZj();//中介处罚事由
              this.getEcharsZj(); //中介处罚机构
            }
          });
        });
      },
      // tag点击事件
      filtrate(value) {
        if(!value){
          getYearDetailApi({
            params: {
              key: this.$route.query.key,
              date: this.$route.query.date,
            },
          })
        }else{
          getWeekDetailApi({
            params: {
              key: this.$route.query.key,
              date: this.$route.query.date,
            },
          })
        }
        this.clickFlag = value
      },
      // 加载更多点击事件
      moreOther() {
        this.otherFlag = false;
      },
      punishBankDetail(id) {
        let text = this.$router.resolve({ name: "weeklyBankDetail", query: {
            id : id ,
            key:this.$route.query.key,
            date:this.$route.query.date,
            userId: this.$route.query.UserId,
            groupId: this.groupId,
          } });
        window.open(text.href, '_blank')
      },
      punishSelectDetail(id) {
        let text = this.$router.resolve({ name: "weeklyDetail", query: {
            id : id ,
            key:this.$route.query.key,
            date:this.$route.query.date,
            userId: this.$route.query.UserId,
            groupId: this.groupId,
          } });
        window.open(text.href, '_blank')
      },
      punishOtherDetail(url) {
        window.open(url, '_blank')
      },
      punishDetail(id) {
        let text = this.$router.resolve({ name: "weeklyDetail", query: { id : id ,key:this.$route.query.key,date:this.$route.query.date} });
        window.open(text.href, '_blank')
      },
      punishSpDetail(id) {
        if(id.assList.length == 1) {
          let text = this.$router.resolve({ name: "weeklyDetail", query: {
              id : id.assList[0].punId ,
              key:this.$route.query.key,
              date:this.$route.query.date,
              userId: this.$route.query.UserId,
              groupId: this.groupId,
            } });
          window.open(text.href, '_blank')
        }else{
          window.open(id.otherLink, '_blank')
        }
      },
      getEcharsRsx(){
        var myChart = echarts.init(document.getElementById('punishjg2'));
        const option = {
          legend: {

            x:'right',      //可设定图例在左、右、居中
            padding:[0,60,0,0],
            data: ['罚单(张)',  '罚金（万元）']
          },
          xAxis: [
            {
              type: 'category',
              data: this.weeklyInfo.rsxSubVO.nameList,
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0,//使x轴文字显示全
                formatter: function (value) {
                  return value.split("").join("\n")
                }
              }
            }
          ],
          yAxis: [
            {
              id: '罚单(张)',
              name: '(张)',
              nameTextStyle:{
                padding:[0, 0, 0, -30],
              },
              type: 'value',
              min: 0,
              max: this.weeklyInfo.rsxSubVO.numberMax,
              interval: this.weeklyInfo.rsxSubVO.numberMax/5,
            },
            {
              id: '罚金（万元）',
              name: '万元',
              nameTextStyle:{
                padding:[0, 0, 0, 35],
              },
              type: 'value',
              min: 0,
              max: this.weeklyInfo.rsxSubVO.moneyMax,
              interval: this.weeklyInfo.rsxSubVO.moneyMax/5,
            }
          ],
          dataZoom: [
            {
              type: "inside",  // 支持内部鼠标滚动平移
              start: 0,
              end: (20/this.weeklyInfo.rsxSubVO.nameList.length)*100,
              zoomOnMouseWheel: false,  // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
            }
          ],
          series: [
            {
              id: '罚单(张)',
              name: '罚单(张)',
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#F56981'
                }
              },
              lineStyle: {
                width: 1,
                type: 'solid' //'dotted'虚线 'solid'实线
              },
              data: this.weeklyInfo.rsxSubVO.numberList
            },

            {
              id: '罚金（万元）',
              name: '罚金（万元）',
              type: 'bar',
              barWidth: '40%',
              itemStyle: {
                normal: {
                  color: '#658BFE'
                }
              },
              yAxisIndex: 1,
              data: this.weeklyInfo.rsxSubVO.moneyList
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsYyRsx(){
        var myChart = echarts.init(document.getElementById('rsxyy'));
        const option = {
          legend: {

            x:'right',      //可设定图例在左、右、居中
            padding:[0,60,0,0],
            data: ['罚单(张)',  '罚金（万元）']
          },
          xAxis: [
            {
              type: 'category',
              data: this.weeklyInfo.rsxYySubVO.nameList,
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0,//使x轴文字显示全
                formatter: function (value) {
                  return value.split("").join("\n")
                }
              }
            }
          ],
          yAxis: [
            {
              id: '罚单(张)',
              name: '(张)',
              nameTextStyle:{
                padding:[0, 0, 0, -30],
              },
              type: 'value',
              min: 0,
              max: this.weeklyInfo.rsxYySubVO.numberMax,
              interval: this.weeklyInfo.rsxYySubVO.numberMax/5,
            },
            {
              id: '罚金（万元）',
              name: '万元',
              nameTextStyle:{
                padding:[0, 0, 0, 35],
              },
              type: 'value',
              min: 0,
              max: this.weeklyInfo.rsxYySubVO.moneyMax,
              interval: this.weeklyInfo.rsxYySubVO.moneyMax/5,
            }
          ],
          dataZoom: [
            {
              type: "inside",  // 支持内部鼠标滚动平移
              start: 0,
              end: (20/this.weeklyInfo.rsxYySubVO.nameList.length)*100,
              zoomOnMouseWheel: false,  // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
            }
          ],
          series: [
            {
              id: '罚单(张)',
              name: '罚单(张)',
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#F56981'
                }
              },
              lineStyle: {
                width: 1,
                type: 'solid' //'dotted'虚线 'solid'实线
              },
              data: this.weeklyInfo.rsxYySubVO.numberList
            },

            {
              id: '罚金（万元）',
              name: '罚金（万元）',
              type: 'bar',
              barWidth: '40%',
              itemStyle: {
                normal: {
                  color: '#658BFE'
                }
              },
              yAxisIndex: 1,
              data: this.weeklyInfo.rsxYySubVO.moneyList
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsZj(){
        var myChart = echarts.init(document.getElementById('punishjg1'));
        const option = {
          legend: {

            x:'right',      //可设定图例在左、右、居中
            padding:[0,50,0,0],
            data: ['罚单(张)',  '罚金（万元）']
          },
          xAxis: [
            {
              type: 'category',
              data: this.weeklyInfo.zjSubVO.nameList,
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0,//使x轴文字显示全
                formatter: function (value) {
                  return value.split("").join("\n")
                }
              }
            }
          ],
          yAxis: [
            {
              id: '罚单(张)',
              name: '(张)',
              nameTextStyle:{
                padding:[0, 0, 0, -30],
              },
              type: 'value',
              min: 0,
              max: this.weeklyInfo.zjSubVO.numberMax,
              interval: this.weeklyInfo.zjSubVO.numberMax/5,
            },
            {
              id: '罚金（万元）',
              name: '万元',
              nameTextStyle:{
                padding:[0, 0, 0, 35],
              },
              type: 'value',
              min: 0,
              max: this.weeklyInfo.zjSubVO.moneyMax,
              interval: this.weeklyInfo.zjSubVO.moneyMax/5,
            }
          ],
          dataZoom: [
            {
              type: "inside",  // 支持内部鼠标滚动平移
              start: 0,
              end: (20/this.weeklyInfo.zjSubVO.nameList.length)*100,
              zoomOnMouseWheel: false,  // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true,  // 鼠标移动能触发数据窗口平移
            }
          ],
          series: [
            {
              name: '罚单(张)',
              id: '罚单(张)',
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#F56981'
                }
              },
              lineStyle: {
                width: 1,
                type: 'solid' //'dotted'虚线 'solid'实线
              },
              data: this.weeklyInfo.zjSubVO.numberList
            },

            {
              name: '罚金（万元）',
              id: '罚金（万元）',
              type: 'bar',
              barWidth: '40%',
              itemStyle: {
                normal: {
                  color: '#658BFE'
                }
              },
              yAxisIndex: 1,
              data: this.weeklyInfo.zjSubVO.moneyList
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsReasonRsx(){
        var myChart = echarts.init(document.getElementById('reason1'));
        const  option = {
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value',
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              splitLine: {
                show: false
              }
            }
          ],
          yAxis: [
            {
              type: 'category',
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              data: this.weeklyInfo.rsxReasonVO.nameList,
              axisLabel: {
                textStyle: {
                  fontSize: 12
                },
                formatter: (val) => {
                  let c = document.createElement("canvas");
                  const ctx = c.getContext("2d");
                  ctx.font = "16px normal"; // 设置画布内的字体，与设置的textStyle一致
                  const arr = val.split("");
                  arr.map((item) => ctx.measureText(item).width)
                          .reduce((pre, next, index) => {
                            const nLen = pre + next;
                            if (nLen > 180) {
                              arr[index - 1] += "\n";
                              return next;
                            } else {
                              return nLen;
                            }
                          });
                  c = null;
                  return arr.join("");
                }
              }
            }
          ],
          series: [
            {
              type: 'bar',
              label: {
                show: true,
                position: 'right'
              },
              barWidth: '40%',
              itemStyle: {
                normal: {
                  //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                  barBorderRadius: [0, 30, 30, 0],
                  color: '#658BFE'
                }
              },
              emphasis: {
                focus: 'series'
              },
              data: this.weeklyInfo.rsxReasonVO.reasonNumList
            },
            {
              type: 'bar',
              itemStyle: {
                normal: { color: 'rgba(0,0,0,0.05)' }
              },
              barWidth: '40%',
              barGap: '-100%',
              data: this.weeklyInfo.rsxReasonVO.maxLenList,
              animation: false
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsReasonZj(){
        var myChart = echarts.init(document.getElementById('reason2'));
        const  option = {
          legend: {
            data: ['Profit', 'Expenses']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value',
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              splitLine: {
                show: false
              }
            }
          ],
          yAxis: [
            {
              type: 'category',
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              data: this.weeklyInfo.zjReasonVO.nameList,
              axisLabel: {
                textStyle: {
                  fontSize: 12
                },
                formatter: (val) => {
                  let c = document.createElement("canvas");
                  const ctx = c.getContext("2d");
                  ctx.font = "16px normal"; // 设置画布内的字体，与设置的textStyle一致
                  const arr = val.split("");
                  arr.map((item) => ctx.measureText(item).width)
                          .reduce((pre, next, index) => {
                            const nLen = pre + next;
                            if (nLen > 180) {
                              arr[index - 1] += "\n";
                              return next;
                            } else {
                              return nLen;
                            }
                          });
                  c = null;
                  return arr.join("");
                }
              }
            }
          ],
          series: [
            {
              type: 'bar',
              label: {
                show: true,
                position: 'right'
              },
              barWidth: '40%',
              itemStyle: {
                normal: {
                  //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                  barBorderRadius: [0, 30, 30, 0],
                  color: '#658BFE'
                }
              },
              emphasis: {
                focus: 'series'
              },
              data: this.weeklyInfo.zjReasonVO.reasonNumList
            },
            {
              type: 'bar',
              itemStyle: {
                normal: { color: 'rgba(0,0,0,0.05)' }
              },
              barWidth: '40%',
              barGap: '-100%',
              data: this.weeklyInfo.zjReasonVO.maxLenList,
              animation: false
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsCcx(){
        var myChart = echarts.init(document.getElementById('punishjg3'));
        const option = {
          legend: {

            x:'right',      //可设定图例在左、右、居中
            padding:[0,50,0,0],
            data: ['罚单(张)',  '罚金（万元）']
          },
          xAxis: [
            {
              type: 'category',
              data: this.weeklyInfo.ccxSubVO.nameList,
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0,//使x轴文字显示全
                formatter: function (value) {
                  return value.split("").join("\n")
                }
              }
            }
          ],
          yAxis: [
            {
              id: '罚单(张)',
              name: '(张)',
              nameTextStyle:{
                padding:[0, 0, 0, -30],
              },
              type: 'value',
              min: 0,
              max: this.weeklyInfo.ccxSubVO.numberMax,
              interval: this.weeklyInfo.ccxSubVO.numberMax/5,
            },
            {
              id: '罚金（万元）',
              name: '万元',
              nameTextStyle:{
                padding:[0, 0, 0, 35],
              },
              type: 'value',
              min: 0,
              max: this.weeklyInfo.ccxSubVO.moneyMax,
              interval: this.weeklyInfo.ccxSubVO.moneyMax/5,
            }
          ],
          dataZoom: [
            {
              type: "inside",  // 支持内部鼠标滚动平移
              start: 0,
              end: (20/this.weeklyInfo.ccxSubVO.nameList.length)*100,
              zoomOnMouseWheel: false,  // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
            }
          ],
          series: [
            {
              name: '罚单(张)',
              id: '罚单(张)',
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#F56981'
                }
              },
              lineStyle: {
                width: 1,
                type: 'solid' //'dotted'虚线 'solid'实线
              },
              data: this.weeklyInfo.ccxSubVO.numberList
            },

            {
              name: '罚金（万元）',
              id: '罚金（万元）',
              type: 'bar',
              barWidth: '40%',
              itemStyle: {
                normal: {
                  color: '#658BFE'
                }
              },
              yAxisIndex: 1,
              data: this.weeklyInfo.ccxSubVO.moneyList
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsYyCcx(){
        var myChart = echarts.init(document.getElementById('ccxyy'));
        const option = {
          legend: {

            x:'right',      //可设定图例在左、右、居中
            padding:[0,50,0,0],
            data: ['罚单(张)',  '罚金（万元）']
          },
          xAxis: [
            {
              type: 'category',
              data: this.weeklyInfo.ccxYySubVO.nameList,
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0,//使x轴文字显示全
                formatter: function (value) {
                  return value.split("").join("\n")
                }
              }
            }
          ],
          yAxis: [
            {
              id: '罚单(张)',
              name: '(张)',
              nameTextStyle:{
                padding:[0, 0, 0, -30],
              },
              type: 'value',
              min: 0,
              max: this.weeklyInfo.ccxYySubVO.numberMax,
              interval: this.weeklyInfo.ccxYySubVO.numberMax/5,
            },
            {
              id: '罚金（万元）',
              name: '万元',
              nameTextStyle:{
                padding:[0, 0, 0, 35],
              },
              type: 'value',
              min: 0,
              max: this.weeklyInfo.ccxYySubVO.moneyMax,
              interval: this.weeklyInfo.ccxYySubVO.moneyMax/5,
            }
          ],
          dataZoom: [
            {
              type: "inside",  // 支持内部鼠标滚动平移
              start: 0,
              end: (20/this.weeklyInfo.ccxYySubVO.nameList.length)*100,
              zoomOnMouseWheel: false,  // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
            }
          ],
          series: [
            {
              name: '罚单(张)',
              id: '罚单(张)',
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#F56981'
                }
              },
              lineStyle: {
                width: 1,
                type: 'solid' //'dotted'虚线 'solid'实线
              },
              data: this.weeklyInfo.ccxYySubVO.numberList
            },

            {
              name: '罚金（万元）',
              id: '罚金（万元）',
              type: 'bar',
              barWidth: '40%',
              itemStyle: {
                normal: {
                  color: '#658BFE'
                }
              },
              yAxisIndex: 1,
              data: this.weeklyInfo.ccxYySubVO.moneyList
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsReasonCcx(){
        var myChart = echarts.init(document.getElementById('reason3'));
        const  option = {
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value',
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              splitLine: {
                show: false
              }
            }
          ],
          yAxis: [
            {
              type: 'category',
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              data: this.weeklyInfo.ccxReasonVO.nameList,
              axisLabel: {
                textStyle: {
                  fontSize: 12
                },
                formatter: (val) => {
                  let c = document.createElement("canvas");
                  const ctx = c.getContext("2d");
                  ctx.font = "16px normal"; // 设置画布内的字体，与设置的textStyle一致
                  const arr = val.split("");
                  arr.map((item) => ctx.measureText(item).width)
                          .reduce((pre, next, index) => {
                            const nLen = pre + next;
                            if (nLen > 180) {
                              arr[index - 1] += "\n";
                              return next;
                            } else {
                              return nLen;
                            }
                          });
                  c = null;
                  return arr.join("");
                }
              }
            }
          ],
          series: [
            {
              type: 'bar',
              label: {
                show: true,
                position: 'right'
              },
              barWidth: '40%',
              itemStyle: {
                normal: {
                  //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                  barBorderRadius: [0, 30, 30, 0],
                  color: '#658BFE'
                }
              },
              emphasis: {
                focus: 'series'
              },
              data: this.weeklyInfo.ccxReasonVO.reasonNumList
            },
            {
              type: 'bar',
              itemStyle: {
                normal: { color: 'rgba(0,0,0,0.05)' }
              },
              barWidth: '40%',
              barGap: '-100%',
              data: this.weeklyInfo.ccxReasonVO.maxLenList,
              animation: false
            }
          ]
        };
        option && myChart.setOption(option);
      },
    },
  };
</script>

<style lang="scss" >
  .weekly-background {
    width: 375px;
    min-height: 580.15px;
    background: url("../../../../assets/image/weekly/background.png") no-repeat;
    background-color: #2e6adf;
    background-size: 100% ;
    position: absolute;
    z-index: 999;
    overflow: hidden;
    .weekly-tags-left {
      width: 172.5px;
      height: 43.2px;
      position: absolute;
      left: 15px;
      top: 272px;
      background: linear-gradient(-70deg,#30DDF7  0%, #008AFF  100%);
      border-radius: 430px 0px 0px 430px;
    }
    .weekly-tags-right {
      width: 172.5px;
      height: 43.2px;
      position: absolute;
      left: 186.5px;
      top: 272px;
      background: linear-gradient(-70deg, #30DDF7  0%, #008AFF 100%);
      border-radius: 0px 430px 430px 0px;
    }
    .weekly-tags-boder {
      width: 345px;
      height: 43.2px;
      border: 2px solid;
      position: absolute;
      left: 15px;
      top: 272px;
      border-image: linear-gradient(-90deg,#3CF2FF , #008AFF) 10 10;
      border-radius: 430px;
      border: none;
      box-shadow: 0px 0px 5px #3CF2FF;
    }
    .weekly-table-bankgroud {
      width: 349px;
      margin-left: 15px;
      margin-bottom: 20px;
      min-height: 300px;
      background: linear-gradient(-56deg, #EFF7FF 0%, #FFFFFF 100%);
      box-shadow: 0px 3px 7px 0px rgba(0,138,255,0.2);
      border-radius: 10px;
    }
    .weekly-span-style1 {
      width: 119px;
      height: 19.5px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 15px;
      text-shadow: 0px 0px 10px rgba(1,140,254,0.6);
      position: absolute;
      left: 48px;
      top: 284px;
      z-index: 999;
    }
    .weekly-span-style2 {
      width: 128.5px;
      height: 17px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #30DDF7;
      line-height: 15px;
      position: absolute;
      left: 204px;
      top: 285px;
      cursor: pointer;
      z-index: 999;
    }
    .weekly-span-style3 {
      width: 119px;
      height: 19.5px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #30DDF7;
      line-height: 15px;
      position: absolute;
      left: 48px;
      top: 284px;
      cursor: pointer;
      z-index: 999;
    }

    .weekly-span-style4 {
      width: 146px;
      height: 19.5px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 15px;
      text-shadow: 0px 0px 10px rgba(1,140,254,0.6);
      position: absolute;
      left: 200px;
      top: 284px;
      z-index: 999;
    }
    .weekly-div-background-s {
      background-image: url("../../../../assets/image/weekly/yj-s.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      margin-top: 15px;
      min-height: 80px;
    }
    .weekly-div-background-z {
      background-image: url("../../../../assets/image/weekly/yj-z.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      min-height: 50px;
    }
    .weekly-div-background-x {
      background-image: url("../../../../assets/image/weekly/yj-x.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      min-height: 23px;
    }
    .weekly-div-background-one {
      background-image: url("../../../../assets/image/weekly/di-one.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      min-height: 105.5px;
    }
    .weekly-div-background-two {
      background-image: url("../../../../assets/image/weekly/di-two.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      min-height: 128.8px;
    }
    .weekly-div-background-three {
      background-image: url("../../../../assets/image/weekly/di-three.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      min-height: 152.1px;
    }
    .weekly-div-background-four {
      background-image: url("../../../../assets/image/weekly/di-four.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      min-height: 175.4px;
    }
    .weekly-font-title {
      width: 58.5px;
      height: 18.5px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #15233B;
      line-height: 80px;
    }
    .weekly-font-no-title {
      width: 282px;
      height: 41.5px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #15233B;
      line-height: 25px;
    }
    .weekly-font-no-title-three {
      width: 282px;
      height: 41.5px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #15233B;
      line-height: 25px;
    }
    .weekly-font-no-title-four {
      width: 282px;
      height: 41.5px;
      font-size: 14.5px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #15233B;
      line-height: 25px;
    }
    .weekly-font-no-content {
      width: 67.5px;
      height: 13px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #15233B;
      line-height: 20px;
      margin-left: 20px;
    }
    .weekly-ensemble-title {
      width: 58.5px;
      height: 18.5px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #15233B;
      line-height: 60px;
    }
    .weekly-punish-title {
      width: 242px;
      height: 21.5px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 18.5px;
    }
    .weekly-punish-title1 {
      width: 242px;
      height: 21.5px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 11.5px;
    }
    .weekly-punish-div-background {
      background-image: url("../../../../assets/image/weekly/lj-s.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 346px;
      min-height: 200px;
      margin-left: 15px;
    }
    .weekly-punish-div-background-z {
      background-image: url("../../../../assets/image/weekly/lj-z.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 345px;
      margin-left: 15px;
      min-height: 50px;
    }
    .weekly-punish-div1 {
      width: 345px;
      min-height: 332px;
      height: auto;
      background: #FFFFFF;
      margin-left: 15px;
    }
    .weekly-bank-punish-div1 {
      width: 345px;
      min-height: 190px;
      height: auto;
      border-radius: 0px 0px 10px 10px;
      background: #FFFFFF;
      margin-left: 15px;
    }
    .weekly-punish-div2 {
      width: 345px;
      height: 360px;
      background: #FFFFFF;
      margin-left: 15px;
    }
    .weekly-punish-div-yybf {
      width: 345px;
      height: 415px;
      background: #FFFFFF;
      margin-left: 15px;
    }
    .weekly-punish-div3 {
      width: 345px;
      min-height: 502px;
      height: auto;
      background: #FFFFFF;
      border-radius: 0px 0px 10px 10px;
      margin-left: 15px;
    }

    .weekly-punish-date {
      width: 63px;
      height: 26.5px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 14px;
      text-shadow: 0px 3.5px 9px rgba(38,74,141,0.6);
    }
    .weekly-font-ensemble {
      width: 310px;
      height: 111.5px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #433F5E;
      line-height: 24px;
    }
    .weekly-font-table {
      width: 300px;
      height: 111.5px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #15233B;
      line-height: 20px;
    }
    .weekly-font-person {
      width: 244px;
      height: 22.5px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #433F5E;
      line-height: 24px;
      margin-left: 10px;
    }
    .weekly-font-more {
      width: 52px;
      height: 12px;
      font-size: 13px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #1472FF;
    }
    .weekly-punish-div-fxbg {
      background-image: url("../../../../assets/image/weekly/fxbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 334px;
      min-height: 59.5px;
      margin-top: 25px;
      margin-bottom: 30px;
    }
    .weekly-punish-div-fxbg1 {
      background-image: url("../../../../assets/image/weekly/fxbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 334px;
      min-height: 59.5px;
      margin-top: 25px;
      margin-bottom: 30px;
      margin-left: 7px;
    }
    .weekly-punish-div-person {
      background-image: url("../../../../assets/image/weekly/person-bankground.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 82px;
      min-height: 31px;
    }
    .weekly-punish-div-person-font {
      width: auto;
      height: 13.5px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 26px;
      margin-left: 20px;
    }
    .weekly-font-person-beizhu {
      font-size: 13px;
      font-family: PingFang-SC-Medium;
      font-weight: 300;
      color: #666666;
    }

    .weekly-punish-div-group {
      background-image: url("../../../../assets/image/weekly/fenlei.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 55px;
      height: 24px;
      margin-left: 21px;
      margin-bottom: 5px;
      text-align: center;
    }

    .weekly-punish-div-group-font {
      width: 27.5px;
      height: 13.5px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 20px;
    }

    .weekly-font-table-beizhu {
      font-size: 12px;
      font-family: PingFang-SC-Medium;
      font-weight: 300;
      color: #666666;
    }

    .weekly-el-table-st {
      width: 321px;
      border-radius: 10px;
      border-color: #008AFF;
      background: transparent !important;
    }
    .regional-table{
      background: rgba(0,138,255,0.05);
      width: 321px;
      margin-left: 14px;
      border-radius: 10px;

    }
    .regional-table-bankground{
      background-image: url("../../../../assets/image/weekly/wen-li.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-color: black;
      width: 321px;
    }
    .regional-table-bank{
      width: 320px;
      margin-left: 14px;
      border-radius: 10px;

    }
    .regional-table-bank-bankground{
      background-size: 100% 100%;
      width: 320px;
    }
  }

  .el-main-weekly {
    width: 100%!important;
    height: 100%!important;
    background: #ffffff !important;
  }
  // 进度条样式
  .el-progress-bar__outer,
  .el-progress-bar__inner {
    border-radius: inherit;
  }
  .el-table--border {
    border-bottom: 1px solid #EBEEF5;
  }
  .el-progress-bar__innerText {
    color: #433F5E;
    font-size: 11px;
    margin: 0 3px;
    transform: rotate(180deg);
  }

  .el-table--border::after, .el-table--group::after{
    width: 0;
  }

  .el-progress-bar__innerText1 {
    .el-progress-bar__innerText{
      color: #433F5E;
      font-size: 11px;
      margin: 0 3px;
      transform: rotate(0deg);
    }
  }

</style>
