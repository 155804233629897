<template>
  <div>

    <div class="login">
      <div class="login_box">
        <login></login>
      </div>

      <!--备案号   Copyright © 2021-2022 趋创合规 版权所有 | 京ICP备2021030981号-1-->
      <div class="copyright"></div>
    </div>
<!--    <a style="position: absolute;z-index: 999;bottom: 1%;left: 45%;font-size: 16px;" target="_blank" href="https://beian.miit.gov.cn/">-->
<!--      京ICP备2021030981号-1-->
<!--    </a>-->
  </div>

</template>

<script>
  import login from "../ui/loginDj.vue";
  export default {
    components: { login},
    data() {
      return {
        activeName: "first",
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      gai() {
        this.activeName = "first";
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login {
    width: 100%;
    height: 100vh;
    // border-top: 1px solid transparent;
    background: url("../../../assets/image/login/login-dj-background.png") no-repeat;
    background-size: 100% 100%;
    // background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  .login_box {
    // width: 1271px;
    width: 4.06rem;
    // width: 66%;
    // height: 694px;
    height: 100%;
    background: url("../../../assets/image/login/login-user-background.png") no-repeat;
    background-size: cover;
    margin-right: 0px;
    position: relative;
    display: flex;
    float: right;
  }

  .copyright {
    height: 40px;
    width: 20px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
</style>